/* TAILWIND IMPORT */
@tailwind base;
@tailwind components;
@tailwind utilities;

/*CUSTOM CSS*/
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden
}

#root {
  height: 100%;
}
